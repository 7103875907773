<template>
  <b-modal
    id="set-batch-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
    @show="getData"
  >
    <div class="modal-header">
      <div class="modal-title">Vincular lote</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="4">
          <b-form-group id="batch-select">
            <validation-observer>
              <div class="label">
                <label for="batch-select"> Lote </label>
              </div>
              <multiselect
                id="batchs"
                v-model="selectedBatch"
                :options="batchOptions"
                class="with-border"
                :showLabels="false"
                track-by="label"
                label="label"
                placeholder="Selecionar"
                @select="selectBatch"
                :allow-empty="false"
                ref="multiselect"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option?.number }} 
                  <span class="gray"> 
                    - {{ option?.count }} guia(s)
                  </span>
                </template>
                <template slot="option" slot-scope="{option}">
                  {{ option?.number }} 
                  <span class="gray"> 
                    - {{ option?.count }} guia(s)
                  </span>
                </template>
                <template slot="noOptions"> Nenhuma </template>
                <template slot="afterList">
                  <li @click="onCreateNewBatch">
                    <div class="multiselect__option new-batch">
                      <Plus class="icon" />
                      Criar novo lote
                    </div>  
                  </li>
                </template>
              </multiselect>
            </validation-observer>
          </b-form-group>
        </b-col>
        
        <b-col cols="8">
          <b-form-group>
              <label for="name"> 
                Nome
                <span class="help"> (Opcional)</span>
              </label>
              <b-input
                id="name"
                autocomplete="off"
                placeholder="Descrever"
                v-model="name"
                :disabled="!newBatch"
              />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="my-3 d-flex justify-content-end">
          <b-button variant="outline-danger" class="mr-3" @click="closeModal">
            Cancelar
          </b-button>
          <b-button variant="primary" v-if="canSave" @click="handleSave">
            Vincular e salvar
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { SusBatch} from '@/modules/sus/manageGuides/utils/statuses'
import api from '@/modules/sus/api'

export default {
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Plus: () => import('@/assets/icons/plus.svg')
  },
  props: {
    selectedGuides: Array,
    type: String
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      name: null,
      selectedBatch: null,
      batchOptions: [],
      newBatch: false,
      susInformation: null
    }
  },
  computed: {
    canSave() {
      return this.selectedBatch
    }
  },
  methods: {
    closeModal() {
      this.newBatch = false
      this.selectedBatch = false
      this.$bvModal.hide('set-batch-modal')
    },
    async getData() {
      await this.getSusInformation()
      await this.getSusBatchesOptions()
    },
    async getSusInformation(){
      const isLoading = this.$loading.show()

      try {
        const { data } = await api.getInformationSusSettingByClinicId(
          this.clinic.id
        )
        this.susInformation = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getSusBatchesOptions(){
      const isLoading = this.$loading.show()

      try {
        const { data } = await api.getSusBatchesOptions(
          this.clinic.id,
          this.type
        )
        this.batchOptions = data?.length ? data : [] 

      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    onCreateNewBatch() {
      this.selectedBatch = {
        name: 'SUS-' + this.type,
        number: this.type === "APAC" ? this.susInformation.current_apac_batch_number : this.susInformation.current_bpa_batch_number,
        type: this.type === "APAC" ? "App\\Models\\Sus\\ApacGuide" : "App\\Models\\Sus\\BpaGuide" ,
        count: 0
      }
      this.name = this.selectedBatch.name
      this.newBatch = true
    },
    selectBatch(opt){
      this.selectedBatch = opt
      this.name = opt?.name
      this.newBatch = false
    },

    handleSave() {
      if (!this.newBatch) {
        this.saveGuidesBatchId()
        return
      }
      const isLoading = this.$loading.show()
      const payload = {
        clinic_id: this.clinic.id,
        name: this.name,
        number: this.selectedBatch.number,
        type: this.selectedBatch?.type,
        status: SusBatch.BATCH_STATUS_TO_SENT
      }
      api
        .createSusBatch(payload)
        .then(res => {
          this.selectedBatch.id = res.data.id
          this.newBatch = false
          this.saveGuidesBatchId()
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
      isLoading.hide()
    },

    async saveGuidesBatchId() {
      const isLoading = this.$loading.show();
      const payload = {
        guide_type: this.selectedBatch.type ? this.selectedBatch.type : this.selectedGuides[0].guide_type, 
        batch_id: this.selectedBatch.id,
        guide_ids: this.selectedGuides.map(guide => guide.id)
      }
      api
        .updateBatchIdManyGuides(payload)
        .then(res => {
          this.$toast.success(res.data)
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          isLoading.hide()
          this.$emit('onSave')
          this.closeModal()
        })
    }
  }
}
</script>

<style lang="scss">
#set-batch-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;

      .form-group {
        label {
          font-size: 1rem;
          font-weight: 600;
        }

        .form-control {
          font-size: 1rem;
          color: var(--type-active);
        }

        .options-content,
        .select-option-container {
          font-size: 1rem;
          font-weight: 500;
          color: var(--type-active);
        }

        .disabled {
          pointer-events: none;

          .form-control {
            background: var(--neutral-100);
          }
        }
        .new-batch {
          color: var(--blue-500);
          font-weight: bold;
          display: flex;
          align-items: center;
        }

        .icon {
          width: 20px;
          height: 20px;
          fill: var(--blue-500);
          margin-right: 4px;
        }
      }
    }
  }

  .gray{
    color: gray;
  }
}
</style>
